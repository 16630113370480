.flex-row {
  display: flex;
  flex-direction: row; }

.flex-col {
  display: flex;
  flex-direction: column; }

.flex-align-center {
  align-items: center; }

.flex-align-start {
  align-items: flex-start; }

.flex-grow {
  flex-grow: 1; }

.flex-shrink {
  flex-shrink: 1; }

.flex-spacer {
  flex: 1 1 100%; }

.flex-space-between {
  justify-content: space-between; }

.flex-horizontal-center {
  display: flex;
  flex-direction: row;
  justify-content: center; }
