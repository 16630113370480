$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@font-face {
  font-family: 'icons';
  src: url('./icons.eot?93372000');
  src: url('./icons.eot?93372000#iefix') format('embedded-opentype'), url('./icons.woff2?93372000') format('woff2'), url('./icons.woff?93372000') format('woff'), url('./icons.ttf?93372000') format('truetype'), url('./icons.svg?93372000#icons') format('svg');
  font-weight: normal;
  font-style: normal; }

.Icon {
  display: inline-block;
  font-style: normal;

  &:focus {
    outline: none; }

  &:before {
    font-family: 'icons';
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    // margin-right: .2em
    margin-top: -2px;
    text-align: center;
    vertical-align: middle;
    /* opacity: .8 */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    // margin-left: .2em

    /* you can be more comfortable with increased icons size */
    /* font-size: 120% */
    font-size: 1.1em;

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3) */ }

  &-blue {
    &:before, & > svg {
      color: var(--tru-blue); } }

  &-subtle {
    &:before {
      opacity: 0.5; }
    &:hover:before {
      opacity: 1; } }

  // // Note: i guessed at what huge should be - Jared
  // &-hg
  //   font-size: 1.8em
  //   line-height: 0.55em
  //   vertical-align: -.0467em

  &-svg {
    outline-offset: 2px;
    &:before {
      content: none; }
    > svg {
      vertical-align: -0.22em;
      width: 19px;
      path {
        fill: currentColor; } } }
  &-xs > svg {
    width: 13px; }
  &-sm > svg {
    width: 15px; }
  &-lg > svg {
    width: 23px; }


  // <TEMP FIXES>
  & {
    // Icons that are too fat
    &-favorite-active,
    &-favorite-non,
    &-balance-scale,
    &-id-card-o,
    &-vimeo {
      &:before {
        width: 1.2em; } }
    // SVG icons that are the wrong vertical size
    &-singlecomment svg {
      position: relative;
      top: 0.1em; } }
  // </TEMP FIXES>

  // copied from font-awesome
  // https://github.com/FortAwesome/Font-Awesome/blob/master/web-fonts-with-css/css/fontawesome.css
  &-lg {
    font-size: 1.33333em;
    line-height: 0.75em;
    vertical-align: -.0667em; }

  &-xs {
    font-size: .75em; }

  &-sm {
    font-size: .875em; }

  @for $i from 1 through 10 {
    &-#{$i}x {
      font-size: 1em * $i; } }

  &-rotate-45 {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }

  &-rotate-90 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg); }

  &-rotate-180 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }

  &-rotate-270 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg); }

  &-transitionRotation {
    transition: transform 100ms ease-out; }

  &-spin {
    -webkit-animation: Icon-spin 2s infinite linear;
    animation: Icon-spin 2s infinite linear; }

  @-webkit-keyframes Icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg); } }

  &-none:before {
    content: ' '; } }
