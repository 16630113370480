@import "./responsive-breakpoints.scss";

@mixin onScreensNarrowerThan($breakpoint) {
  @if map_has_key($breakpoints, $breakpoint) {
    $width: map_get($breakpoints, $breakpoint);
    @media only screen and (max-width: $width) {
      @content; } }
  @else {
    @error "illegal breakpoint #{$breakpoint}"; } }

@mixin onScreensWiderThan($breakpoint) {
  @if map_has_key($breakpoints, $breakpoint) {
    $width: map_get($breakpoints, $breakpoint) + 1;
    @media only screen and (min-width: $width) {
      @content; } }
  @else {
    @error "illegal breakpoint #{$breakpoint}"; } }

@mixin onScreensBetween($left, $right) {
  $min-width: map_get($breakpoints, $left) + 1;
  $max-width: map_get($breakpoints, $right);
  @media only screen and (min-width: $min-width) and (max-width: $max-width) {
    @content; } }

@mixin removeVerticalMarginFromChildren() {
  > * {
    &:first-child {
      margin-top: 0; }
    &:last-child {
      margin-bottom: 0; } } }

@mixin contentBoxBorder() {
  border-radius: 3px;
  box-shadow: var(--content-box-box-shadow); }

@mixin delayInputFocusBorder() {
  box-shadow: 0 0 0 1px transparent, 0 0 0 3px transparent;
  transition: box-shadow 0ms;
  transition-delay: 10ms; }

@mixin inputFocusBorder() {
  box-shadow: 0 0 0 1px white, 0 0 0 3px var(--input-focus-border-color); }

@mixin undoInputFocusBorder() {
  box-shadow: none; }

@mixin inputBorder() {
  border: 1px solid var(--border-grey);
  &:focus, &:focus-within {
    @include inputFocusBorder; } }

@mixin undoInputBorder() {
  border: 1px solid transparent;
  &:focus, &:focus-within {
    @include undoInputFocusBorder; } }

@mixin fillPositionedParent() {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%; }

@mixin spaceVertically {
  & + & {
    margin-top: var(--vertical-space); } }

@mixin noSelectOrDrag {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none;
  -webkit-touch-callout: none; }

@mixin shroud {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  background-color: rgba(0,0,0,0.5);
  z-index: 1000; }

@mixin isPWA {
  .isPWA & {
    @content; } }

@mixin isDataYogi {
  html.isDataYogi & {
    @content; } }

@mixin circledAlert {
  font-size: 80%;
  line-height: 6px;
  min-width: 20px;
  border-radius: 100px;
  padding: calc(var(--horizontal-padding) / 2);
  display: flex;
  justify-content: center; }

@mixin centeredColumn {
  @include onScreensWiderThan('tablet') {
    width: 61.803%;
    margin-left: auto;
    margin-right: auto; } }

@mixin faded {
  opacity: 0.5;
  &:hover, &:active, &:focus {
    opacity: 1; } }

@mixin onDevicesWithHover {
  @media not all and (hover: none) {
    @content; } }

@mixin signupAndLoginForms {
  @include onScreensNarrowerThan('phone') {
    width: 100%;
    box-shadow: none;
    border: none; }
  @include onScreensWiderThan('phone') {
    max-width: 550px;
    min-width: 400px; } }

@mixin padded {
  padding: var(--vertical-padding) var(--horizontal-padding); }

@mixin spaced {
  margin: var(--vertical-space) var(--horizontal-space); }
