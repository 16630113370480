$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.ErrorMessage {
  overflow: hidden;
  display: block;
  &-alertWrapper {
    position: relative; }
  &-dismissButton {
    position: absolute;
    color: var(--error-red);
    top: 4px;
    right: 2px;
    opacity: .8;
    cursor: pointer;
    z-index: 1;
    &:hover {
      opacity: 1; } }
  &-error {
    padding: 10px;
    margin-bottom: 10px;
    width: auto; }
  &-dismissable &-error {
    padding-right: 20px; }
  &-slideDown {
    &-enter {
      max-height: 0;
      align-items: flex-end; }
    &-enter-active {
      max-height: 100px;
      transition: max-height 300ms ease-out; }
    &-exit {
      max-height: 100px;
      align-items: flex-end; }
    &-exit-active {
      max-height: 0;
      transition: max-height 200ms ease-out; } } }
