$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.Alert {
  display: flex;
  position: relative;
  margin: 0;
  border-radius: 4px;
  background-color: #fff;
  transition: opacity 200ms;
  opacity: 1;
  width: fit-content;

  &-typeIcon {
    padding: var(--vertical-padding) var(--horizontal-padding); }

  &-content {
    display: flex;
    align-items: center;
    padding-right: var(--horizontal-padding);
    overflow: hidden;
    word-break: break-word; }

  &-placeholder {
    opacity: 0; }

  &-success {
    background-color: #f0f9eb;
    border-left: 5px solid #67c23a; }
  &-info {
    background-color: #f4f4f5;
    border-left: 5px solid #909399; }
  &-warning {
    background-color: #fdf6ec;
    border-left: 5px solid #e6a23c; }
  &-error {
    background-color: #fef0f0;
    border-left: 5px solid var(--error-red); }

  &-info &-typeIcon, {
    color: #909399; }
  &-warning &-typeIcon, {
    color: #e6a23c; }
  &-error &-typeIcon, {
    color: var(--error-red); }
  &-success &-typeIcon {
    color: #67c23a; }

  &-dismissButton {
    display: flex;
    align-items: center;
    padding: 0 var(--horizontal-padding);
    border-left: 1px solid var(--border-grey-lightened);
    cursor: pointer;
    color: var(--greyed-out);
    font-size: 1em; } }
