$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.RequestPasswordResetForm {
  padding: 15px 20px;
  .Header {
    margin-bottom: 15px;
    text-align: center; }
  .PhoneInput {
    display: flex; }
  &-success {
    &-link {
      text-align: center;
      margin-top: 15px; } }
  .ErrorMessage-alertWrapper {
    margin-bottom: 10px; } }
