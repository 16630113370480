$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.SideNav {
  background: var(--side-nav-background);
  color: var(--side-nav-text-color);
  padding: 0px 0 20px 0;
  > &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; }

  &-Header {
    display: block;
    margin: 5px 0 0;
    padding: 10px 20px;
    font-size: .65rem;
    line-height: 25px;
    font-weight: 800; }

  &-Button {
    $vertical-padding: 10px;
    position: relative;
    display: block;
    width: 100%;
    padding: $vertical-padding 0 $vertical-padding 10px;
    text-align: left;
    white-space: nowrap;
    &:hover {
      background: var(--side-nav-background-button-hover); }
    &-disabled {
      opacity: 0.5; }
    &-selected {
      background: var(--side-nav-background-button-selected);
      &:hover {
        background: var(--side-nav-background-button-selected); }
      &:after {
        position: absolute;
        top: $vertical-padding;
        bottom: $vertical-padding;
        left: 0;
        width: 2px;
        background-color: var(--side-nav-selected-after);
        content: " "; } }
    > .Icon {
      margin-right: 0.25em; } }

  &-Dropdown {
    .SideNav-Button-selected {
      background-color: inherit; }
    .SideNav-Button > span {
      margin-left: -3px; }
    &-Option {
      display: block;
      position: relative;
      padding: 5px 0 5px 32px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        background: var(--side-nav-background-button-hover); }
      &-selected {
        &:after {
          content: "•";
          color: var(--side-nav-selected-after);
          position: absolute;
          top: 5px;
          left: 18px; } } } } }
