$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.BobLayout {
  .Layout {
    &-topnav {
      @include onScreensWiderThan('tablet') {
        height: calc( var(--top-nav-height) + 2px ); } // these two pixels are for the dropshadow
      .Hamburger {
        @include onScreensWiderThan('tablet') {
          display: none; } } }
    &-overlay {
      @include onScreensWiderThan('tablet') {
        display: none; } }
    &-rightcontent {
      display: none; } }

  .OrganizationFeed-Post {
    border: none; }

  &-adminHeader {
    text-align: center;
    font-size: 15px;
    padding-bottom: 0px;
    color: orange; } }
