$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.Icon-marketplace:before { content: '\e800'; }
.Icon-question:before { content: '\e801'; }
.Icon-down-dir:before { content: '\e802'; }
.Icon-edit:before { content: '\e803'; }
.Icon-inside:before { content: '\e804'; }
.Icon-card-share:before { content: '\e805'; }
.Icon-help:before { content: '\e806'; }
.Icon-master-data:before { content: '\e807'; }
.Icon-dot-3:before { content: '\e808'; }
.Icon-attach:before { content: '\e809'; }
.Icon-brandposts:before { content: '\e80a'; }
.Icon-mail:before { content: '\e80b'; }
.Icon-help-circled:before { content: '\e80c'; }
.Icon-calendar:before { content: '\e80e'; }
.Icon-wrench:before { content: '\e810'; }
.Icon-menu:before { content: '\e811'; }
.Icon-down-bold:before { content: '\e812'; }
.Icon-globe:before { content: '\e813'; }
.Icon-no-contact:before { content: '\e814'; }
.Icon-up-bold:before { content: '\e815'; }
.Icon-bell:before { content: '\e816'; }
.Icon-doc-new:before { content: '\e817'; }
.Icon-back:before { content: '\e818'; }
.Icon-card-edit:before { content: '\e819'; }
.Icon-card-import:before { content: '\e81a'; }
.Icon-reply:before { content: '\e81b'; }
.Icon-forward:before { content: '\e81c'; }
.Icon-podcast:before { content: '\e81d'; }
.Icon-info:before { content: '\e81e'; }
.Icon-donate:before { content: '\e820'; }
.Icon-upload:before { content: '\e821'; }
.Icon-download:before { content: '\e822'; }
.Icon-credit-card:before { content: '\e825'; }
.Icon-picture:before { content: '\e826'; }
.Icon-star:before { content: '\e82b'; }
.Icon-network:before { content: '\e82c'; }
.Icon-outside:before { content: '\e82d'; }
.Icon-spaces:before { content: '\e82f'; }
.Icon-pledge:before { content: '\e831'; }
.Icon-cog:before { content: '\e83e'; }
.Icon-favorite-active:before { content: '\e83f'; }
.Icon-favorite-non:before { content: '\e840'; }
.Icon-hubs-list:before { content: '\e841'; }
.Icon-recent:before { content: '\e843'; }
.Icon-sub-to:before { content: '\e844'; }
.Icon-sub-me:before { content: '\e845'; }
.Icon-user-list:before { content: '\e846'; }
.Icon-ar-down:before { content: '\e847'; }
.Icon-ar-up:before { content: '\e848'; }
.Icon-checkmark:before { content: '\e84c'; }
.Icon-top:before { content: '\e84e'; }
.Icon-active:before { content: '\e84f'; }
.Icon-down-vote:before { content: '\e850'; }
.Icon-recent_1:before { content: '\e851'; }
.Icon-up-vote:before { content: '\e852'; }
.Icon-hub-list:before { content: '\e892'; }
.Icon-organization:before { content: '\e893'; }
.Icon-topical:before { content: '\e894'; }
.Icon-channel:before { content: '\e8c9'; }
.Icon-cropp:before { content: '\e8cb'; }
.Icon-favorites:before { content: '\e8cc'; }
.Icon-filter:before { content: '\e8cd'; }
.Icon-home-circled:before { content: '\e8cf'; }
.Icon-circle-thin:before { content: '\e8d0'; }
.Icon-market:before { content: '\e8d1'; }
.Icon-my-feed:before { content: '\e8d2'; }
.Icon-my-hubs:before { content: '\e8d3'; }
.Icon-notification-bell:before { content: '\e8d4'; }
.Icon-people:before { content: '\e8d5'; }
.Icon-public-feed:before { content: '\e8d6'; }
.Icon-search:before { content: '\e8d7'; }
.Icon-telegram:before { content: '\e8d8'; }
.Icon-user:before { content: '\e8d9'; }
.Icon-login:before { content: '\e904'; }
.Icon-user-add:before { content: '\e905'; }
.Icon-attention:before { content: '\e906'; }
.Icon-down-open:before { content: '\f004'; }
.Icon-up-open:before { content: '\f005'; }
.Icon-right-open:before { content: '\f006'; }
.Icon-left-open:before { content: '\f007'; }
.Icon-log-out:before { content: '\f02d'; }
.Icon-chat:before { content: '\f03d'; }
.Icon-linkedin:before { content: '\f05c'; }
.Icon-ok-circled:before { content: '\f06d'; }
.Icon-cancel-circled:before { content: '\f06e'; }
.Icon-eye:before { content: '\f082'; }
.Icon-circle:before { content: '\f111'; }
.Icon-angle-circled-down:before { content: '\f13a'; }
.Icon-share:before { content: '\f14d'; }
.Icon-data-tr:before { content: '\f1c0'; }
.Icon-file-audio:before { content: '\f1c7'; }
.Icon-history:before { content: '\f1da'; }
.Icon-bell-off-empty:before { content: '\f1f7'; }
.Icon-balance-scale:before { content: '\f24e'; }
.Icon-id-card-o:before { content: '\f2c3'; }
.Icon-facebook-rect:before { content: '\f301'; }
.Icon-twitter:before { content: '\f302'; }
.Icon-twitter-bird:before { content: '\f303'; }
.Icon-vimeo-rect:before { content: '\f30e'; }
.Icon-vimeo:before { content: '\f30f'; }
.Icon-tumblr-rect:before { content: '\f310'; }
.Icon-linkedin-rect:before { content: '\f31b'; }
.Icon-instagram-filled:before { content: '\f31f'; }
.Icon-comment:before { content: '\f4ac'; }
