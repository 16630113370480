$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.Loading {
  display: flex;
  align-items: center;
  justify-content: center;

  &-block {
    padding: 10px;
    margin: 10px; }

  &-fullScreen, &-fullPage {
    width: 100%;
    @include onScreensNarrowerThan('phone') {
      align-items: flex-start;
      padding-top: 150px; } }

  &-fullScreen {
    background-color: var(--navy-blue);
    height: 100vh; }

  &-fullPage {
    align-self: center;
    justify-self: center;
    flex-grow: 1; }

  &-sm {
    .Loading-spinner {
      height: 20px;
      width: 20px; } }
  &-md {
    .Loading-spinner {
      height: 50px;
      width: 50px; } }
  &-lg {
    .Loading-spinner {
      height: 100px;
      width: 100px; } }

  &-spinner {
    background-image: url(./knot.svg);
    background-position: center;
    background-size: cover;
    -webkit-animation: Loading-spin 2s infinite linear;
    animation: Loading-spin 2s infinite linear; }

  @-webkit-keyframes Loading-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg); } }

  &-delayed {
    $animation-delay: 500ms;
    $animation-duration: 300ms;
    opacity: 0;
    animation: $animation-duration ease-in $animation-delay forwards Loading-delayed;
    -webkit-animation: $animation-duration ease-in $animation-delay forwards Loading-delayed; }

  @-webkit-keyframes Loading-delayed {
    0% {
      opacity: 0; }
    50% {
      opacity: 0; }
    100% {
      opacity: 1; } } }
