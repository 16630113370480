$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.Form {

  &-Label {
    display: block;
    margin: 0;
    font-size: 0.7em;
    line-height: 2;
    font-weight: 700;
    white-space: nowrap;
    text-overflow: ellipsis;
    letter-spacing: 0.5px; }
  &-LabelSuffix {
    color: var(--tru-blue);
    display: inline; }

  &-Item {
    margin-bottom: var(--vertical-space);
    // > *
 }    //   min-width: 100%
  &-Row {
    @include onScreensNarrowerThan('phone') {
      display: block; }

    @include onScreensWiderThan('phone') {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .Form-Item {
        flex: 1 1 50%;
        max-width: 100%;
        &:not(:last-child) {
          flex: 1 1 calc(50% - var(--horizontal-space));
          margin-right: var(--horizontal-space); } } } }

  &-ButtonRow {
    @include spaceVertically;
    white-space: nowrap;
    display: flex;
    align-items: center;
    &:not(&-reverse) {
      flex-direction: row;
      justify-content: flex-end;
      > *:not(:first-child) {
        margin-left: calc(var(--horizontal-space) / 2); } }
    &-reverse {
      flex-direction: row-reverse;
      justify-content: flex-start;
      > *:not(:last-child) {
        margin-left: calc(var(--horizontal-space) / 2); } } } }
