$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.TextArea {
  position: relative;
  background-color: #fff;
  display: flex;
  align-items: stretch;
  --TextArea-vertical-padding: 5px;
  --TextArea-horizontal-padding: 10px;
  --line-height: 25px;
  font-size: 100%;

  &[data-max-rows] {
    max-height: calc(attr(data-max-rows number, 1) * 25px); }

  &-Counter {
    position: absolute;
    bottom: calc(100% + 2px);
    right: 0.25em;
    opacity: 0;
    transition: opacity 200ms ease-in-out; }
  &:focus-within &-Counter {
    opacity: 1; }

  > div {
    visibility: hidden; }

  > textarea, > div {
    box-sizing: content-box;
    min-height: var(--line-height);
    line-height: var(--line-height);
    overflow: auto;
    flex-direction: column;
    color: #5a5e66;
    border-radius: 4px;
    @include inputBorder;
    padding: var(--TextArea-vertical-padding) var(--TextArea-horizontal-padding);
    outline: none;
    background: transparent;
    display: block;
    width: 100%;
    box-shadow: none;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: inherit; }

  > textarea {
    resize: vertical;
    &:focus {
      outline: none; }
    &::placeholder {
      color: #b4bccc; }
    &[disabled] {
      border-color: #f5f7fa;
      background-color: #f5f7fa;
      resize: none;
      color: #606060;
      -webkit-text-fill-color: #606060;
      opacity: 1;
      cursor: not-allowed; }
    &:-webkit-autofill {
      -webkit-text-fill-color: #5a5e66; } }

  &-autoResize > textarea {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: calc(100% - (var(--TextArea-vertical-padding) * 2));
    width: calc(100% - (var(--TextArea-horizontal-padding) * 2)); }

  &-resize {
    &-none textarea {
      resize: none; }
    &-both textarea {
      resize: both; }
    &-horizontal textarea {
      resize: horizontal; }
    &-vertical textarea {
      resize: vertical; } }

  &-unsaved {
    background-color: var(--unsaved-background-color);
    border-color: var(--unsaved-border-color);
    textarea {
      &::placeholder {
        color: #787d88; }
      &[disabled] {
        border-color: var(--unsaved-and-disabled-border-color);
        background-color: var(--unsaved-and-disabled-background-color); } } } }
